import { createApi } from "@reduxjs/toolkit/query/react";

import { userEndpoints } from "./user";
import { authEndpoints } from "./auth";
import { reportsEndpoints } from "./reports";
import { trainingEndpoints } from "./train";
import { generateEndpoints } from "./generate";
import { productsEndpoints } from "./product";

import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ordersEndpoints } from "./orders";
import { promptsEndpoints } from "./prompts";
import { analyticsEndpoints } from "./analytics";

const baseQuery = fetchBaseQuery({
  baseUrl: "https://devrest.garagefarm.net",
  credentials: "include",
});

const query = async (args, api, extraOptions) => {
  const result = baseQuery(args, api, extraOptions);
  return result;
};

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: query,
  endpoints: (builder) => ({
    ...authEndpoints(builder),
    ...userEndpoints(builder),
    ...reportsEndpoints(builder),
    ...trainingEndpoints(builder),
    ...generateEndpoints(builder),
    ...productsEndpoints(builder),
    ...ordersEndpoints(builder),
    ...promptsEndpoints(builder),
    ...analyticsEndpoints(builder),
  }),
});

export const {
  useLoginMutation,
  useGetUsersMutation,
  useAddCreditsMutation,
  useActivateMutation,
  useMakeAdminMutation,
  useGetReportsMutation,
  useUpdateReportMutation,
  useTrainMutation,
  useCheckpointsMutation,
  useLorasMutation,
  useFlux_lorasMutation,
  useSamplersMutation,
  useFlux_samplersMutation,
  useSchedulersMutation,
  useGetProductImagesMutation,
  useSetProductImagesMutation,
  useDeleteProductImageMutation,
  useModifyImageTypeMutation,
  useGetSingleOrderMutation,
  useUpdateOrderMutation,
  useJobInfoMutation,
  useVersionMutation,
  useGenerateMutation,
  useComfyMutation,
  usePromptMutation,
  useCreatePromptMutation,
  useUpdatePromptMutation,
  useGetReleasedProductsMutation,
  useModifyProductsOrderMutation,
  useModifyFeaturedOrderMutation,
  useTempReleaseProductMutation,
  useGetOrdersDataMutation,
  useGetLorasDataMutation,
  useGetSubscriptionUsageMutation,
  useGetSubscribersMutation,
  useGetRevenuecatMutation,
  useTrainFluxMutation,
  useDeleteSingleOrderMutation,
  useGetProductTypeMutation,
  useUpdateProductMutation,
  useGetProductVideosMutation,
  useSetProductVideosMutation,
  useDeleteProductVideoMutation,
  useGetProductMutation,
  useGetPromptByNameMutation,
  useValidateProductMutation,
  useUpscaleMutation,
  useGetFeedbackMutation,
  useTrainShuttleMutation,
  useDeleteShuttleMutation,
  useFaceswapMutation,
  useDeleteFluxMutation,
} = apiSlice;
