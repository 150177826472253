import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  useCheckpointsMutation,
  useLorasMutation,
  useSamplersMutation,
  useFlux_samplersMutation,
  useSchedulersMutation,
  useFlux_lorasMutation,
} from "api/apiSlice";
import {
  setCheckpoints,
  setLoras,
  setSamplers,
  setFluxSamplers,
  setSchedulers,
  setFluxLoras,
} from "state/generate";

const useInitApi = () => {
  const dispatch = useDispatch();
  const [checkpoints, { isLoading }] = useCheckpointsMutation();
  const [loras, { isLoadingLoras }] = useLorasMutation();
  const [samplers, { isLoadingSamplers }] = useSamplersMutation();
  const [flux_samplers] = useFlux_samplersMutation();
  const [schedulers] = useSchedulersMutation();
  const [flux_loras] = useFlux_lorasMutation();

  const getCheckpoints = async () => {
    const checkpointsList = await checkpoints().unwrap();
    dispatch(setCheckpoints(checkpointsList.models));
  };
  const getLoras = async () => {
    const lorasList = await loras().unwrap();
    dispatch(setLoras(lorasList));
  };

  const getSamplers = async () => {
    const samplersList = await samplers().unwrap();
    dispatch(setSamplers(samplersList.samplers));
  };
  const getFluxSamplers = async () => {
    const fluxSamplersList = await flux_samplers().unwrap();
    dispatch(setFluxSamplers(fluxSamplersList.samplers));
  };
  const getSchedulers = async () => {
    const sch = await schedulers().unwrap();
    dispatch(setSchedulers(sch.schedulers));
  };
  const getFluxLoras = async () => {
    const lorasList = await flux_loras().unwrap();
    dispatch(setFluxLoras(lorasList));
  };

  useEffect(() => {
    getLoras();
    getCheckpoints();
    getSamplers();
    getFluxSamplers();
    getSchedulers();
    getFluxLoras();
  }, []);

  return isLoading && isLoadingLoras && isLoadingSamplers;
};

export default useInitApi;
