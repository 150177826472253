import { useState, useEffect, useRef } from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";

import { Button, IconButton, TextField } from "@mui/material";

import { useCms } from "utils/CMS";
import { useAuth } from "utils/Auth";
import { convertBase64 } from "utils/generate";

import SmallDialog from "components/SmallDialog";
import Toast from "components/Toast";
import {
  useDeleteFluxMutation,
  useDeleteShuttleMutation,
  useTrainFluxMutation,
  useTrainShuttleMutation,
} from "api/apiSlice";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 80,
    backgroundColor: "#383838",
    color:
      theme.palette.mode === "light"
        ? "rgb(255, 255, 255)"
        : "rgb(255, 255, 255)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: "rgb(255, 255, 255)",
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function LoraEditMenu({ name, lora, gender, handleRemoveLora }) {
  const { updateLoraThumb, updateLoraGender, updateLoraName } = useCms();
  const { getLorasList } = useAuth();

  const [trainFlux] = useTrainFluxMutation();
  const [trainShuttle] = useTrainShuttleMutation();
  const [deleteShuttle] = useDeleteShuttleMutation();
  const [deleteFlux] = useDeleteFluxMutation();

  const [alert, setAlert] = useState({
    show: false,
    severity: "success",
    msg: "",
  });

  const inputRef = useRef();
  const newNameRef = useRef();

  const [anchorEl, setAnchorEl] = useState(null);
  const [changeNameDialogOpen, setChangeNameDialogOpen] = useState(false);
  const [changeNameDialogData, setChangeNameDialog] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeThumb = () => {
    console.log("change thumb");

    inputRef.current.click();
  };
  const handleChangeGender = () => {
    const g = gender === "male" ? "female" : "male";
    updateLoraGender(name, g).then((resp) => {
      if (resp.success) {
        setAnchorEl(null);
        setAlert({
          show: true,
          severity: "success",
          msg: "Gender updated",
        });
        getLorasList();
      } else {
        setAnchorEl(null);
        setAlert({
          show: true,
          severity: "error",
          msg: resp.msg,
        });
      }
    });
  };

  useEffect(() => {
    if (changeNameDialogData) {
      handleChangeName();
    }
  }, [changeNameDialogData]);

  const handleChangeName = () => {
    updateLoraName(name, newNameRef.current.value).then((resp) => {
      if (resp.success) {
        setAnchorEl(null);
        setAlert({
          show: true,
          severity: "success",
          msg: "Name changed",
        });
        getLorasList();
      } else {
        setAnchorEl(null);
        setAlert({
          show: true,
          severity: "error",
          msg: resp.msg,
        });
      }
    });
  };

  const handleUpload = (file) => {
    console.log(file);
    convertBase64(file)
      .then((img) => {
        updateLoraThumb(name, img.split(",")[1])
          .then((resp) => {
            if (resp.success) {
              setAnchorEl(null);
              setAlert({
                show: true,
                severity: "success",
                msg: "Thumbnail updated",
              });
              getLorasList();
            } else {
              setAlert({
                show: true,
                severity: "error",
                msg: resp.msg,
              });
            }
          })
          .catch((e) => {
            console.error(e);
          });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleRemove = () => {
    setAnchorEl(null);
    handleRemoveLora(lora);
  };

  const train = async () => {
    console.log(name);
    const data = { name: name };
    const res = await trainFlux(data).unwrap();
    setAnchorEl(null);
    setAlert({
      show: true,
      severity: "success",
      msg: "flux training submitted",
    });
  };
  const train_shuttle = async () => {
    console.log(name);
    const data = { name: name };
    const res = await trainShuttle(data).unwrap();
    setAnchorEl(null);
    setAlert({
      show: true,
      severity: "success",
      msg: "Shuttle training submitted",
    });
  };
  const delete_shuttle = async () => {
    const data = { name: name };
    const res = await deleteShuttle(data).unwrap();
    setAnchorEl(null);
    setAlert({
      show: true,
      severity: res.status === "success" ? "success" : "error",
      msg: res.msg,
    });
  };
  const delete_flux = async () => {
    const data = { name: name };
    const res = await deleteFlux(data).unwrap();
    setAnchorEl(null);
    setAlert({
      show: true,
      severity: res.status === "success" ? "success" : "error",
      msg: res.msg,
    });
  };

  return (
    <div>
      <Toast alert={alert} setAlert={setAlert}></Toast>
      <IconButton aria-label="open" onClick={handleClick} variant="contained">
        <MenuOpenIcon fontSize="inherit" sx={{ color: "#fff" }} />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ backdropFilter: "blur(2px)" }}
      >
        <MenuItem onClick={handleRemove} disableRipple>
          Delete
        </MenuItem>
        <MenuItem onClick={handleChangeGender} disableRipple>
          Change gender
        </MenuItem>
        <MenuItem onClick={handleChangeThumb} disableRipple>
          Change thumb
        </MenuItem>
        <MenuItem onClick={setChangeNameDialogOpen}>Change name</MenuItem>
        <MenuItem onClick={train}>Train flux</MenuItem>
        <MenuItem onClick={train_shuttle}>Train shuttle</MenuItem>
        <MenuItem onClick={delete_shuttle}>Delete shuttle</MenuItem>
        <MenuItem onClick={delete_flux}>Delete flux</MenuItem>
      </StyledMenu>

      <input
        ref={inputRef}
        type="file"
        onChange={(e) => handleUpload(e.target.files[0])}
        accept="image/*"
        style={{ display: "none" }}
      />
      <SmallDialog
        title="Change LORA name"
        open={changeNameDialogOpen}
        setOpen={setChangeNameDialogOpen}
        setData={handleChangeName}
        confirmButton="Save"
        cancelButton="Cancel"
      >
        <TextField
          margin="normal"
          id="name"
          label="name"
          name="name"
          inputRef={newNameRef}
          className="ring-0"
          sx={{ width: "400px" }}
          size="small"
          onKeyDown={(e) => e.stopPropagation()}
        />
      </SmallDialog>
    </div>
  );
}
