import { createSlice } from "@reduxjs/toolkit";
import { prompt_dataclass } from "data";

const initialState = {
  loadedPrompt: prompt_dataclass,
  promptListDialogOpen: false,
  isQueueOpen: false,
  checkpoints: [],
  selectedCheckpoint: "1_sd_xl_base_1.0",
  loras: [],
  flux_loras: [],
  selected_flux_loras: [
    { name: "flux_realism_lora.safetensors", selected: false, strength: 1 },
    {
      name: "araminta_k_flux_film_foto.safetensors",
      selected: false,
      strength: 1,
    },
    { name: "paradiffflux.safetensors", selected: false, strength: 1 },
  ],
  selectedLoras: [],
  selectedLorasCount: {},
  loraStrength: 1,
  lorasDialogOpen: false,
  quickLoraSelect: false,
  samplers: [],
  flux_samplers: [],
  schedulers: [],
  isGenerating: false,
  batch: 1,
  hires: { enabled: false, scale: 1.5 },
  saveAsData: { name: "", category: "", product: "", newProduct: "" },
  orderLoaded: false,
  orderData: {},
  extraLoras: [],
};

export const generateSlice = createSlice({
  name: "generate",
  initialState,
  reducers: {
    setLoadedPrompt: (state, action) => {
      //action.payload = {key: '', data: {}}
      //action.payload = {key: 'width', data: 2048}
      if (typeof action.payload.key == "string") {
        state.loadedPrompt =
          action.payload.key !== ""
            ? {
                ...state.loadedPrompt,
                [action.payload.key]: action.payload.data,
              }
            : action.payload.data;
      } else {
        console.log("disptach", action.payload.key);
        if (action.payload.key.length === 2) {
          state.loadedPrompt = {
            ...state.loadedPrompt,
            [action.payload.key[0]]: {
              ...state.loadedPrompt[action.payload.key[0]],
              [action.payload.key[1]]: action.payload.data,
            },
          };
        }
        if (action.payload.key.length === 3) {
          console.log("disptach", action.payload.key);
          state.loadedPrompt = {
            ...state.loadedPrompt,
            [action.payload.key[0]]: {
              ...state.loadedPrompt[action.payload.key[0]],
              [action.payload.key[1]]: {
                ...state.loadedPrompt[action.payload.key[0]][
                  action.payload.key[1]
                ],
                [action.payload.key[2]]: action.payload.data,
              },
            },
          };
        }
      }
    },
    setPromptListDialogOpen: (state, action) => {
      // state.promptListDialogOpen = action.payload;
      return {
        ...state,
        promptListDialogOpen: action.payload,
      };
    },
    toggleQueueDrawer: (state, action) => {
      // state.isQueueOpen = action.payload;
      return {
        ...state,
        isQueueOpen: action.payload,
      };
    },
    setCheckpoints: (state, action) => {
      // state.checkpoints = action.payload;
      return {
        ...state,
        checkpoints: action.payload,
      };
    },
    setSelectedCheckpoint: (state, action) => {
      // state.selectedCheckpoint = action.payload;
      return {
        ...state,
        loadedPrompt: { ...state.loadedPrompt, checkpoint: action.payload },
        selectedCheckpoint: action.payload,
      };
    },
    setLoras: (state, action) => {
      // state.loras = action.payload;
      return {
        ...state,
        loras: action.payload,
      };
    },
    setSelectedLoras: (state, action) => {
      // state.selectedLoras = action.payload;
      return {
        ...state,
        selectedLoras: action.payload,
      };
    },
    setSelectedLorasCount: (state, action) => {
      // state.selectedLorasCount = action.payload;
      return {
        ...state,
        selectedLorasCount: action.payload,
      };
    },
    setLoraStrength: (state, action) => {
      // state.loraStrength = action.payload;
      return {
        ...state,
        loraStrength: action.payload,
      };
    },
    setLoraDialogOpen: (state, action) => {
      // state.lorasDialogOpen = action.payload;
      return {
        ...state,
        lorasDialogOpen: action.payload,
      };
    },
    setQuickLoraSelect: (state, action) => {
      // state.quickLoraSelect = action.payload;
      return {
        ...state,
        quickLoraSelect: action.payload,
      };
    },
    setSamplers: (state, action) => {
      // state.samplers = action.payload;
      return {
        ...state,
        samplers: action.payload,
      };
    },
    setFluxSamplers: (state, action) => {
      // state.samplers = action.payload;
      return {
        ...state,
        flux_samplers: action.payload,
      };
    },
    setSchedulers: (state, action) => {
      // state.samplers = action.payload;
      return {
        ...state,
        schedulers: action.payload,
      };
    },
    setFluxLoras: (state, action) => {
      // state.loras = action.payload;
      return {
        ...state,
        flux_loras: action.payload,
      };
    },
    setSelectedFluxLoras: (state, action) => {
      // state.selectedLoras = action.payload;
      return {
        ...state,
        selected_flux_loras: action.payload,
      };
    },
    setIsGenerating: (state, action) => {
      // state.isGenerating = action.payload;
      return {
        ...state,
        isGenerating: action.payload,
      };
    },
    setBatch: (state, action) => {
      // state.batch = action.payload;
      return {
        ...state,
        batch: action.payload,
      };
    },
    setHires: (state, action) => {
      // state.hires = {
      //   ...state.hires,
      //   [action.payload.key]: action.payload.data,
      // };
      return {
        ...state,
        hires: { ...state.hires, [action.payload.key]: action.payload.data },
      };
    },
    setSaveAsData: (state, action) => {
      // console.log(action.payload);
      // state.saveAsData = {
      //   ...state.saveAsData,
      //   [action.payload.key]: action.payload.data,
      // };
      return {
        ...state,
        saveAsData: {
          ...state.saveAsData,
          [action.payload.key]: action.payload.data,
        },
      };
    },
    setDefaultInitialState: (state, action) => {
      // state = action.payload;
      return {
        ...action.payload,
        checkpoints: state.checkpoints,
        samplers: state.samplers,
        loras: state.loras,
      };
    },
    setOrderLoaded: (state, action) => {
      // state.batch = action.payload;
      return {
        ...state,
        orderLoaded: action.payload.orderLoaded,
        orderData: action.payload.data,
      };
    },
    setExtraLoras: (state, action) => {
      // state.batch = action.payload;
      return {
        ...state,
        extraLoras: action.payload,
      };
    },
  },
});

export const {
  setLoadedPrompt,
  setCheckpoints,
  setSelectedCheckpoint,
  setLoras,
  setSamplers,
  setPromptListDialogOpen,
  toggleQueueDrawer,
  setIsGenerating,
  setBatch,
  setHires,
  setSelectedLoras,
  setLoraDialogOpen,
  setLoraStrength,
  setSelectedLorasCount,
  setQuickLoraSelect,
  setSaveAsData,
  setDefaultInitialState,
  setFluxSamplers,
  setSchedulers,
  setFluxLoras,
  setSelectedFluxLoras,
  setOrderLoaded,
  setExtraLoras,
} = generateSlice.actions;

export const loadedPrompt = (state) => state.generate.loadedPrompt;
export const checkpoints = (state) => state.generate.checkpoints;
export const loras = (state) => state.generate.loras;
export const samplers = (state) => state.generate.samplers;
export const isGenerating = (state) => state.generate.isGenerating;

export const promptListDialogOpen = (state) =>
  state.generate.promptListDialogOpen;
export const isQueueOpen = (state) => state.generate.isQueueOpen;

export default generateSlice.reducer;
