export const APP_VERSION = "3.1.56";
export const speciesValues = ["human", "cat", "dog"];
export const genderValues = ["male", "female"];
export const productionValues = ["Yes", "No", "To review", "Scratchpad"];

export const prompt_dataclass = {
  _id: "",
  author: "",
  category: "",
  cfg: 7,
  checkpoint: "1_sd_xl_base_1.0",
  checkpointVersion: "xl",
  createdAt: "",
  creator_rating: 1,
  gender: "male",
  height: "1024",
  isProduction: "",
  loraStrength: 1,
  modified_by: "",
  name: "",
  note: [],
  nprompt: "",
  nprompts: {
    cat_female: "",
    cat_male: "",
    dog_female: "",
    dog_male: "",
    human_female: "",
    human_male: "",
  },
  params: {
    cfg: {
      cat_female: 7,
      cat_male: 7,
      dog_female: 7,
      dog_male: 7,
      human_female: 7,
      human_male: 7,
    },
    guidance: {
      cat_female: 40,
      cat_male: 40,
      dog_female: 40,
      dog_male: 40,
      human_female: 40,
      human_male: 40,
    },
    lorastrength: {
      cat_female: 1,
      cat_male: 1,
      dog_female: 1,
      dog_male: 1,
      human_female: 1,
      human_male: 1,
    },
    refiner: {
      cat_female: false,
      cat_male: false,
      dog_female: false,
      dog_male: false,
      human_female: false,
      human_male: false,
    },
    refinersteps: {
      cat_female: 25,
      cat_male: 25,
      dog_female: 25,
      dog_male: 25,
      human_female: 25,
      human_male: 25,
    },
    sampler: {
      cat_female: "DPM++ 2M SDE Karras",
      cat_male: "DPM++ 2M SDE Karras",
      dog_female: "DPM++ 2M SDE Karras",
      dog_male: "DPM++ 3M SDE Karras",
      human_female: "DPM++ 3M SDE Karras",
      human_male: "DPM++ 3M SDE Karras",
    },
    sampler2: {
      cat_female: "DPM++ 2M SDE Karras",
      cat_male: "DPM++ 2M SDE Karras",
      dog_female: "DPM++ 2M SDE Karras",
      dog_male: "DPM++ 3M SDE Karras",
      human_female: "DPM++ 3M SDE Karras",
      human_male: "DPM++ 3M SDE Karras",
    },
    steps: {
      cat_female: 30,
      cat_male: 30,
      dog_female: 30,
      dog_male: 30,
      human_female: 30,
      human_male: 30,
    },
    steps2: {
      cat_female: 30,
      cat_male: 30,
      dog_female: 30,
      dog_male: 30,
      human_female: 30,
      human_male: 30,
    },
    scheduler: {
      cat_female: "beta",
      cat_male: "beta",
      dog_female: "beta",
      dog_male: "beta",
      human_female: "beta",
      human_male: "beta",
    },
    scheduler2: {
      cat_female: "beta",
      cat_male: "beta",
      dog_female: "beta",
      dog_male: "beta",
      human_female: "beta",
      human_male: "beta",
    },
  },
  parent: "",
  prompt: "",
  prompts: {
    cat_female: "",
    cat_male: "",
    dog_female: "",
    dog_male: "",
    human_female: "",
    human_male: "",
  },
  reviewer_rating: 1,
  sampler: "DPM++ 3M SDE Karras",
  seed: [],
  species: "human",
  steps: 30,
  thumb: "",
  width: "1024",
  extraLoras: {
    cat_female: [],
    cat_male: [],
    dog_female: [],
    dog_male: [],
    human_female: [],
    human_male: [],
  },
};
export const initialState = {
  loadedPrompt: prompt_dataclass,
  promptListDialogOpen: false,
  isQueueOpen: false,
  checkpoints: [],
  selectedCheckpoint: "1_sd_xl_base_1.0",
  loras: [],
  selectedLoras: [],
  selectedLorasCount: {},
  loraStrength: 1,
  lorasDialogOpen: false,
  quickLoraSelect: false,
  samplers: [],
  isGenerating: false,
  batch: 1,
  hires: { enabled: false, scale: 1.5 },
  saveAsData: { name: "", category: "", product: "", newProduct: "" },
  flux_samplers: [],
  schedulers: [],
  orderLoaded: false,
  orderData: {},
  selected_flux_loras: [
    { name: "flux_realism_lora.safetensors", selected: false, strength: 1 },
    {
      name: "araminta_k_flux_film_foto.safetensors",
      selected: false,
      strength: 1,
    },
    { name: "paradiffflux.safetensors", selected: false, strength: 1 },
  ],
  extraLoras: [],
  // extraLoras: [
  //   { name: "flux_realism_lora", strength: 1 },
  //   {
  //     name: "araminta_k_flux_film_foto",
  //     strength: 1,
  //   },
  //   { name: "paradiffflux", strength: 1 },
  // ],
};
