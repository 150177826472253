export const trainingEndpoints = (builder) => {
  return {
    train: builder.mutation({
      query: (data) => ({
        url: "/train",
        method: "post",
        body: data,
      }),
    }),
    trainFlux: builder.mutation({
      query: (data) => ({
        url: "/trainFlux",
        method: "post",
        body: data,
      }),
    }),
    trainShuttle: builder.mutation({
      query: (data) => ({
        url: "/trainShuttle",
        method: "post",
        body: data,
      }),
    }),
    deleteShuttle: builder.mutation({
      query: (data) => ({
        url: "/deleteShuttle",
        method: "post",
        body: data,
      }),
    }),
    deleteFlux: builder.mutation({
      query: (data) => ({
        url: "/deleteFlux",
        method: "post",
        body: data,
      }),
    }),
  };
};
