/* eslint-disable no-unused-vars */
import { useState, useEffect, useRef } from "react";

import { useSearchParams } from "react-router-dom";

import Box from "@mui/material/Box";
import {
  Button,
  Container,
  IconButton,
  Paper,
  Tooltip,
  useTheme,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import LinearProgress from "@mui/material/LinearProgress";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import CasinoIcon from "@mui/icons-material/Casino";
import RecyclingIcon from "@mui/icons-material/Recycling";
import DeleteIcon from "@mui/icons-material/Delete";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import SimpleAccordion from "components/Accordian";
import SmallDialog from "components/SmallDialog";
import FullScreenDialog from "components/FullScreenDialog";
import Gallery from "components/Carousel";
import Toast from "components/Toast";
import PromptHistoryDialog from "components/PromptHistoryDialog";
import StepedSlider from "components/StepedSlider";
import LoraDialog from "scenes/lora/LoraDialog";
import Dropdown from "components/Dropdown";

import { savePromptLocally, getDateAndTime } from "utils/generate";

import { makeid } from "utils/globalUtils";

import { useAuth } from "utils/Auth";
import { useCms } from "utils/CMS";
import useSse from "utils/useSse";

import {
  speciesValues,
  genderValues,
  productionValues,
  prompt_dataclass,
  initialState,
} from "data";
import PromptsList from "../PromptList/PromptsList";
import SaveAs from "./SaveAs";
import ProductGenerate from "./ProductGenerate";
import { useGenerate } from "./Context";
import useCheckpointSettings from "./hooks/useCheckpointSettings";
import CheckpointSettings from "./Settings/CheckpointSettings";
import Sidebar from "./Orders/Sidebar";
import MenuBar from "./Menu_bar";

import { useDispatch, useSelector } from "react-redux";
import {
  useCheckpointsMutation,
  useCreatePromptMutation,
  useGenerateMutation,
  useJobInfoMutation,
  usePromptMutation,
  useUpdatePromptMutation,
  useComfyMutation,
  useFlux_samplersMutation,
  useSchedulersMutation,
  useGetPromptByNameMutation,
  useFaceswapMutation,
} from "api/apiSlice";
import {
  setLoadedPrompt,
  promptListDialogOpen,
  setPromptListDialogOpen,
  samplers as samplersList,
  setIsGenerating,
  setSelectedCheckpoint,
  setBatch,
  setHires,
  setSaveAsData,
  setDefaultInitialState,
  toggleQueueDrawer,
  setSelectedLoras,
  setFluxSamplers,
  setSchedulers,
  setOrderLoaded,
  setExtraLoras,
} from "state/generate";

import useInitApi from "./hooks/useInitApi";
import { currentUser } from "state/auth";
import PromptTokens from "components/PromptTokens";
import { ArrowBackIos } from "@mui/icons-material";
import ProductsOrder from "scenes/Admin/ProductsOrder";

const Generate = ({ user }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const [query, setquery] = useState("");

  // STATE
  const username = useSelector((state) => state.authentication.email);
  const isAdmin = useSelector((state) => state.authentication.isAdmin);
  const loadedPrompt = useSelector((state) => state.generate.loadedPrompt);
  const samplers = useSelector(samplersList);
  const flux_samplers = useSelector((state) => state.generate.flux_samplers);
  const schedulers = useSelector((state) => state.generate.schedulers);
  const isGenerating = useSelector((state) => state.generate.isGenerating);
  const checkpoint = useSelector((state) => state.generate.selectedCheckpoint);
  const batch = useSelector((state) => state.generate.batch);
  const hires = useSelector((state) => state.generate.hires);
  const selectedLoras = useSelector((state) => state.generate.selectedLoras);
  const loras = useSelector((state) => state.generate.loras);
  const saveAsData = useSelector((state) => state.generate.saveAsData);
  const orderLoaded = useSelector((state) => state.generate.orderLoaded);
  const selectedFluxLoras = useSelector(
    (state) => state.generate.selected_flux_loras
  );
  const extraLoras = useSelector((state) => state.generate.extraLoras);
  // const width = loadedPrompt?.width !== ''

  const [isFlux, setIsFlux] = useState(false);

  const [generate] = useGenerateMutation();
  const [comfy] = useComfyMutation();
  const [getJobInfo] = useJobInfoMutation();
  const [getPrompt] = usePromptMutation();
  const [addPrompt] = useCreatePromptMutation();
  const [updatePrompt] = useUpdatePromptMutation();
  const [get_flux_samplers] = useFlux_samplersMutation();
  const [get_schedulers] = useSchedulersMutation();
  const [getPromptByName_new] = useGetPromptByNameMutation();
  const [faceswap] = useFaceswapMutation();

  const promptRef = useRef();
  const npromptRef = useRef();

  const [shwoResolutionWarning, setshwoResolutionWarning] = useState(false);
  const [tempEvent, setTempEvent] = useState();
  const [resolutionWarningData, setResolutionWarningData] = useState(false);
  const [promptNameError, setpromptNameError] = useState(false);
  const [faceSwapEnabled, setfaceSwapEnabled] = useState(false);
  const [faceSwapImage, setfaceSwapImage] = useState();
  /////////////////////////////////////////////////////////////////////////
  const isFetching = useInitApi();

  const { generate2, submitHiresJobs } = useAuth();
  const { addSubcategory, getPromptByName, updateHistory, getHistory } =
    useCms();

  const {
    alert,
    setAlert,
    setResultImage,
    setResultSeeds,
    promptHistoryList,
    setpromptHistoryList,
    promptId,
    setpromptId,
    setbatchStatus,
    loraThumbsList,
    setLoraThumbsList,
    setproductGeneration,
    setproductsCount,
    sethiresGeneration,
    setHiresJobs,
    showBackdrop,
    setshowBackdrop,
    appliedFilters,
    setAppliedFilters,
    saveDialogOpen,
    setSaveDialogOpen,
    saveDialogData,
    setSaveDialogData,
    promptToLoad,
    setPromptToLoad,
    savePromptBlocked,
    setsavePromptBlocked,
    hiresList,
    sethiresList,
  } = useGenerate();

  const { data, setStarted, settotalJobs } = useSse(
    `65b2381451b2b5db88f42a3f_generate`
  );

  // RELOAD
  useEffect(() => {
    console.log("RELOAD");
    promptRef.current.value =
      loadedPrompt.prompts[`${loadedPrompt.species}_${loadedPrompt.gender}`];
    npromptRef.current.value =
      loadedPrompt.nprompts[`${loadedPrompt.species}_${loadedPrompt.gender}`];
    setquery(searchParams.get("order"));
  }, []);

  // BEFORE RELOAD - STORE VALUES IN STATE
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "You have unsaved changes!";

      // localStorage.setItem(
      //   "beforeUnloadData",
      //   `${loadedPrompt.species}_${loadedPrompt.gender}`
      // );
      // // You can set a message to be displayed when the user tries to refresh or leave the page
      // dispatch(
      //   setLoadedPrompt({
      //     key: ["prompts", `${loadedPrompt.species}_${loadedPrompt.gender}`],
      //     data: promptRef.current.value,
      //   })
      // );
      // dispatch(
      //   setLoadedPrompt({
      //     key: ["nprompts", `${loadedPrompt.species}_${loadedPrompt.gender}`],
      //     data: npromptRef.current.value,
      //   })
      // );
    };

    // Add event listener for beforeunload
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // prompt and nprompt update on species \ gender change
  useEffect(() => {
    promptRef.current.value =
      loadedPrompt.prompts[`${loadedPrompt.species}_${loadedPrompt.gender}`];
    npromptRef.current.value =
      loadedPrompt.nprompts[`${loadedPrompt.species}_${loadedPrompt.gender}`];
  }, [loadedPrompt.species, loadedPrompt.gender]);

  useEffect(() => {
    promptRef.current.value =
      loadedPrompt.prompts[`${loadedPrompt.species}_${loadedPrompt.gender}`];
    npromptRef.current.value =
      loadedPrompt.nprompts[`${loadedPrompt.species}_${loadedPrompt.gender}`];
  }, [loadedPrompt.prompts, loadedPrompt.nprompts]);

  useEffect(() => {
    console.log(loadedPrompt.params.scheduler);
  }, [loadedPrompt.params.scheduler]);

  useEffect(() => {
    if (query !== "") {
      const loadPrompt = async () => {
        const info = await getJobInfo(query).unwrap();
        console.log(info);
        if (Object.hasOwn(info, "raw_prompt")) {
          dispatch(setBatch(info.raw_prompt.batch));
          dispatch(setSelectedCheckpoint(info.raw_prompt.checkpoint));
          dispatch(
            setHires({ key: "enabled", data: info.raw_prompt.hires.enabled })
          );
          dispatch(
            setHires({ key: "scale", data: info.raw_prompt.hires.scale })
          );
          dispatch(setSelectedLoras(info.raw_prompt.selectedLoras));
          dispatch(
            setLoadedPrompt({ key: "", data: info.raw_prompt.loadedPrompt })
          );
          dispatch(setLoadedPrompt({ key: "seed", data: info.payload.seed }));
          setAlert({
            show: true,
            severity: "success",
            msg: "Order loaded.",
          });
        } else {
          setAlert({
            show: true,
            severity: "error",
            msg: "Incompatible order.",
          });
        }

        // check if raw_prompt key exists if not ask user if he wants to load everything beside prompt value
        // dispatch all params from payload
      };
    }
  }, [query]);

  useEffect(() => {
    console.log("checkpoint changed");
    if (
      checkpoint === "flux_dev" ||
      checkpoint === "flux_schnell" ||
      checkpoint == "shuttle"
    ) {
      setIsFlux(true);
    } else {
      setIsFlux(false);
    }
  }, [checkpoint]);

  const validatePayload = (obj) => {
    console.log(obj);
    var schema = {};
    if (isFlux) {
      schema = {
        prompt: "string",
        lora: "string",
        lora_owner: "string",
        steps: "number",
        batch: "number",
        width: "number",
        height: "number",
        seed: "number",
        cfg: "number",
        checkpoint: "string",
        sampler: "string",
        hires: "boolean",
        refiner: "boolean",
        refinerSteps: "number",
        upscaleBy: "number",
      };
    } else {
      schema = {
        prompt: "string",
        nprompt: "string",
        lora: "string",
        lora_owner: "string",
        steps: "number",
        batch: "number",
        width: "number",
        height: "number",
        seed: "number",
        cfg: "number",
        checkpoint: "string",
        sampler: "string",
        hires: "boolean",
        refiner: "boolean",
        refinerSteps: "number",
        upscaleBy: "number",
      };
    }

    var result = [];
    var isValid = true;
    if (!obj) {
      result.push(`You need to select at least 1 lora\n`);
      isValid = false;
      return {
        isValid: false,
        feedback: result.join(""),
      };
    }
    // Iterate through each key in the schema
    if (loras.filter((l) => l.selected).length === 0) {
      result.push(`You need to select at least 1 lora\n`);
      isValid = false;
    }
    // if (checkpoint === "flux_dev" || checkpoint === "flux_schnell") {
    //   if (selectedFluxLoras.filter((l) => l.selected).length === 0) {
    //     result.push(`You need to select at least 1 extra lora\n`);
    //     isValid = false;
    //   }
    // }

    for (const key in schema) {
      const expectedType = schema[key];
      const value = obj[key];

      // Check if the key is missing
      if (!(key in obj)) {
        result.push(`Missing key: ${key}.\n`);
        isValid = false;
      }
      // Check if the value is empty, undefined, or null
      if (value === undefined || value === null || value === "") {
        result.push(
          `Invalid value for key "${key}": Value is empty, undefined, or null.\n`
        );
        isValid = false;
      }
      // Check if the type of the value matches the expected type
      if (typeof value !== expectedType) {
        result.push(
          `Invalid type for key "${key}": Expected ${expectedType}, but got ${typeof value}.\n`
        );
        isValid = false;
      }
      // Additional check for NaN values when expected type is number
      if (expectedType === "number" && Number.isNaN(value)) {
        result.push(`Invalid value for key "${key}": Value is NaN`);
        isValid = false;
      }
    }
    if (!isValid) {
      return {
        isValid: false,
        feedback: result.join(""),
      };
    }
    return {
      isValid: true,
      feedback: "",
    };
  };

  useEffect(() => {
    console.log("resolution warning data changed");
    if (resolutionWarningData) {
      console.log(tempEvent);
      handleSubmit(tempEvent, false);
      setResolutionWarningData(false);
    }
  }, [resolutionWarningData]);

  //  1HC5lttxEMcHq5jk_6Q-rXWUc2ZtekHttHBk53BL
  const handleSubmit = async (event, checkResolution = true) => {
    console.log(event);
    var formData = "";
    // console.clear();

    if (checkResolution) {
      event.preventDefault();
      formData = new FormData(event.currentTarget);
    } else {
      formData = event;
    }
    if (checkResolution) {
      console.log("Check resolution");
      if (
        parseInt(formData.get("width")) > 1024 ||
        parseInt(formData.get("height")) > 1024
      ) {
        setshwoResolutionWarning(true);
        setTempEvent(formData);
        return false;
      }
    }
    if (loadedPrompt._id === "") {
      setAlert({
        show: true,
        severity: "error",
        msg: `Save prompt before submitting generation`,
      });
      return false;
    }
    setLoraThumbsList([]);

    sethiresGeneration(false);

    setshowBackdrop(true);

    dispatch(
      setLoadedPrompt({
        key: ["prompts", `${loadedPrompt.species}_${loadedPrompt.gender}`],
        data: promptRef.current.value,
      })
    );
    dispatch(
      setLoadedPrompt({
        key: ["nprompts", `${loadedPrompt.species}_${loadedPrompt.gender}`],
        data: npromptRef.current.value,
      })
    );

    var data = {
      prompt: promptRef.current.value,
      nprompt: npromptRef.current.value,
      steps: parseInt(formData.get("steps")),
      batch: parseInt(formData.get("batch")),
      width: parseInt(formData.get("width")),
      height: parseInt(formData.get("height")),
      seed: formData.get("seed") === "" ? -1 : parseInt(formData.get("seed")),
      cfg: parseFloat(
        loadedPrompt.params.cfg[
          `${loadedPrompt.species}_${loadedPrompt.gender}`
        ]
      ),
      checkpoint: checkpoint,
      sampler: isFlux
        ? flux_samplers.includes(
            loadedPrompt.params.sampler[
              `${loadedPrompt.species}_${loadedPrompt.gender}`
            ]
          )
          ? loadedPrompt.params.sampler[
              `${loadedPrompt.species}_${loadedPrompt.gender}`
            ]
          : "euler"
        : loadedPrompt.params.sampler[
            `${loadedPrompt.species}_${loadedPrompt.gender}`
          ],
      hires: hires.enabled,
      refiner:
        loadedPrompt.params.refiner[
          `${loadedPrompt.species}_${loadedPrompt.gender}`
        ],
      refinerSteps: parseInt(
        loadedPrompt.params.refinersteps[
          `${loadedPrompt.species}_${loadedPrompt.gender}`
        ]
      ),
      upscaleBy: parseFloat(hires.scale),
      scheduler:
        loadedPrompt.params.scheduler[
          `${loadedPrompt.species}_${loadedPrompt.gender}`
        ],
    };
    console.log(`prompt submit: ${data.prompt}`);

    !promptHistoryList.includes(data.prompt) &&
      setpromptHistoryList((current) => [...current, data.prompt]);

    dispatch(setIsGenerating(true));
    if (isFlux) {
      if (faceSwapEnabled) {
        data.extra_loras =
          loadedPrompt.extraLoras[
            `${loadedPrompt.species}_${loadedPrompt.gender}`
          ];
        data.steps_inpaint = parseInt(formData.get("steps2"));
        data.sampler_inpaint = loadedPrompt.params.hasOwnProperty("sampler2")
          ? loadedPrompt.params.sampler2[
              `${loadedPrompt.species}_${loadedPrompt.gender}`
            ]
          : "euler";
        data.scheduler_inpaint = loadedPrompt.params.hasOwnProperty(
          "scheduler2"
        )
          ? loadedPrompt.params.scheduler2[
              `${loadedPrompt.species}_${loadedPrompt.gender}`
            ]
          : "beta";

        data.guidance_inpaint = formData.get("guidance2");

        const payloads = [];
        payloads.push(data);
        const prompt = JSON.parse(JSON.stringify(loadedPrompt));
        prompt.prompts[`${loadedPrompt.species}_${loadedPrompt.gender}`] =
          promptRef.current.value;
        prompt.nprompts[`${loadedPrompt.species}_${loadedPrompt.gender}`] =
          npromptRef.current.value;

        const settings = {
          loadedPrompt: prompt,
          samplers: samplers,
          checkpoint: checkpoint,
          batch: batch,
          hires: hires,
          selectedLoras: loras.filter((l) => l.selected),
          extra_loras:
            loadedPrompt.extraLoras[
              `${loadedPrompt.species}_${loadedPrompt.gender}`
            ],
        };
        try {
          const formData = new FormData();
          const data = {
            user: username,
            user_id: "65b2381451b2b5db88f42a3f",
            prompt: loadedPrompt.name,
            payloads: payloads,
            settings: settings,
          };

          for (let i = 0; i < faceSwapImage.length; i++) {
            formData.append("files", faceSwapImage[i]);
          }
          formData.append("data", JSON.stringify(data));
          const model = checkpoint === "shuttle" ? "shuttle" : "flux";
          const resp = await faceswap({ formData, model }).unwrap();
          // await comfy({
          //   username: username,
          //   prompt: loadedPrompt.name,
          //   payloads: payloads,
          //   settings: settings,
          //   faceSwap: true
          // }).unwrap();
          var nw = window.open(`/order/${resp.data.uuid}`, "_blank");
          nw.blur();
          window.focus();
          setAlert({
            show: true,
            severity: "success",
            msg: "Job submited",
          });
          setshowBackdrop(false);
          setStarted(true);
        } catch (e) {
          setAlert({
            show: true,
            severity: "error",
            msg: `Job not submited: \n ${JSON.stringify(e)}`,
          });

          dispatch(setIsGenerating(false));
          setshowBackdrop(false);
        }
      } else {
        var payloads = [];
        console.log(loras.filter((l) => l.selected));
        loras
          .filter((l) => l.selected)
          .forEach((e, idx) => {
            var newData = {
              ...data,
              lora: e.name,
              lora_owner: e.user,
              lora_strength:
                loadedPrompt.params.lorastrength[
                  `${loadedPrompt.species}_${loadedPrompt.gender}`
                ],
              extra_loras:
                loadedPrompt.extraLoras[
                  `${loadedPrompt.species}_${loadedPrompt.gender}`
                ],
            };
            payloads.push(newData);
          });
        console.log(payloads[0]);
        const result = validatePayload(payloads[0]);
        if (!result.isValid) {
          setAlert({
            show: true,
            severity: "error",
            msg: `Job not added: \n ${result.feedback}`,
          });
          dispatch(setIsGenerating(false));
          setshowBackdrop(false);
          return false;
        }
        const prompt = JSON.parse(JSON.stringify(loadedPrompt));
        prompt.prompts[`${loadedPrompt.species}_${loadedPrompt.gender}`] =
          promptRef.current.value;
        prompt.nprompts[`${loadedPrompt.species}_${loadedPrompt.gender}`] =
          npromptRef.current.value;

        const settings = {
          loadedPrompt: prompt,
          samplers: samplers,
          checkpoint: checkpoint,
          batch: batch,
          hires: hires,
          selectedLoras: loras.filter((l) => l.selected),
          extraLoras: loadedPrompt.extraLoras,
        };
        try {
          console.log(payloads[0]);
          var resp = await comfy({
            username: username,
            prompt: loadedPrompt.name,
            payloads: payloads,
            settings: settings,
          }).unwrap();
          var nw = window.open(`/order/${resp.data.uuid}`, "_blank");
          nw.blur();
          window.focus();
          setAlert({
            show: true,
            severity: "success",
            msg: "Job submited",
          });
          setshowBackdrop(false);
          setStarted(true);
        } catch (e) {
          setAlert({
            show: true,
            severity: "error",
            msg: `Job not submited: \n ${JSON.stringify(e)}`,
          });

          dispatch(setIsGenerating(false));
          setshowBackdrop(false);
        }
      }
    } else {
      settotalJobs(loras.filter((l) => l.selected).length);
      var payloads = [];
      loras
        .filter((l) => l.selected)
        .forEach((e, idx) => {
          var newData = { ...data, lora: e.name, lora_owner: e.user };
          newData.prompt =
            newData.prompt.replace("\n") +
            ` <lora:${e.name}:${
              loadedPrompt.params.lorastrength[
                `${loadedPrompt.species}_${loadedPrompt.gender}`
              ]
            }>`;
          payloads.push(newData);
        });

      const result = validatePayload(payloads[0]);
      if (!result.isValid) {
        setAlert({
          show: true,
          severity: "error",
          msg: `Job not added: \n ${result.feedback}`,
        });
        dispatch(setIsGenerating(false));
        setshowBackdrop(false);
        return false;
      }
      const prompt = JSON.parse(JSON.stringify(loadedPrompt));
      prompt.prompts[`${loadedPrompt.species}_${loadedPrompt.gender}`] =
        promptRef.current.value;
      prompt.nprompts[`${loadedPrompt.species}_${loadedPrompt.gender}`] =
        npromptRef.current.value;

      const settings = {
        loadedPrompt: prompt,
        samplers: samplers,
        checkpoint: checkpoint,
        batch: batch,
        hires: hires,
        selectedLoras: loras.filter((l) => l.selected),
      };
      try {
        var resp = await generate({
          username: username,
          prompt: loadedPrompt.name,
          payloads: payloads,
          settings: settings,
        }).unwrap();
        var nw = window.open(`/order/${resp.data.uuid}`, "_blank");
        nw.blur();
        window.focus();
        setAlert({
          show: true,
          severity: "success",
          msg: "Job submited",
        });
        setshowBackdrop(false);
        setStarted(true);
      } catch (e) {
        setAlert({
          show: true,
          severity: "error",
          msg: `Job not submited: \n ${JSON.stringify(e)}`,
        });

        dispatch(setIsGenerating(false));
        setshowBackdrop(false);
      }
    }
  };

  // SEED UTILS

  const handleSetHires = (checked) => {
    dispatch(setHires({ key: "enabled", data: checked }));

    if (checked) {
      dispatch(setBatch(1));
      setAlert({
        show: true,
        severity: "warning",
        msg: "Batch size changed to 1",
      });
    }
  };

  const handleSetRefinerSteps = (value) => {
    if (value > 50) {
      dispatch(
        setLoadedPrompt({
          key: [
            "params",
            "refinersteps",
            `${loadedPrompt.species}_${loadedPrompt.gender}`,
          ],
          data: 50,
        })
      );
    } else if (value < 0) {
      dispatch(
        setLoadedPrompt({
          key: [
            "params",
            "refinersteps",
            `${loadedPrompt.species}_${loadedPrompt.gender}`,
          ],
          data: 0,
        })
      );
    } else {
      dispatch(
        setLoadedPrompt({
          key: [
            "params",
            "refinersteps",
            `${loadedPrompt.species}_${loadedPrompt.gender}`,
          ],
          data: value,
        })
      );
    }
  };

  const handleChangeSpeciesOrGender = () => {
    console.log(loadedPrompt.prompts);
    console.log(loadedPrompt.gender);
    dispatch(
      setLoadedPrompt({
        key: ["prompts", `${loadedPrompt.species}_${loadedPrompt.gender}`],
        data: promptRef.current.value,
      })
    );
    dispatch(
      setLoadedPrompt({
        key: ["nprompts", `${loadedPrompt.species}_${loadedPrompt.gender}`],
        data: npromptRef.current.value,
      })
    );
    loadedPrompt.params.refinersteps === "" &&
      dispatch(
        setLoadedPrompt({
          key: [
            "params",
            "refinersteps",
            `${loadedPrompt.species}_${loadedPrompt.gender}`,
          ],
          data: 25,
        })
      );
  };

  //Prompt curation
  ////////////////////////////////////////////////////////////////////

  useEffect(() => {}, [loadedPrompt.gender, loadedPrompt.species]);

  // loaded prompt name display
  useEffect(() => {
    document.title = `${loadedPrompt.parent} > ${loadedPrompt.name} - ${loadedPrompt.species} ${loadedPrompt.gender}`;
  }, [loadedPrompt.name, loadedPrompt.gender, loadedPrompt.species]);

  useEffect(() => {
    if (promptNameError) {
      setpromptNameError(false);
    }
  }, [promptNameError]);

  const checkIfPrompExist = async (name) => {};
  // SAVE PROMPT AS
  useEffect(() => {
    console.log(saveDialogData);
    if (saveDialogData) {
      // check if pormpt exist
      getPromptByName_new({
        name: saveAsData.name,
      })
        .unwrap()
        .then((res) => {
          if (res._id !== "0") {
            setSaveDialogData(false);
            // setpromptNameError(true);
            setAlert({
              show: true,
              severity: "error",
              msg: `Prompt with name: ${saveAsData.name} already exists.\n Please use different name and try again`,
            });
            // return;
          } else {
            console.log("save prompt");

            handleChangeSpeciesOrGender();

            const createdAt = getDateAndTime();
            dispatch(
              setLoadedPrompt({
                key: ["checkpoint"],
                data: checkpoint,
              })
            );
            dispatch(
              setLoadedPrompt({
                key: [
                  "prompts",
                  `${loadedPrompt.species}_${loadedPrompt.gender}`,
                ],
                data: promptRef.current.value,
              })
            );
            dispatch(
              setLoadedPrompt({
                key: [
                  "nprompts",
                  `${loadedPrompt.species}_${loadedPrompt.gender}`,
                ],
                data: npromptRef.current.value,
              })
            );
            // dispatch(
            //   setLoadedPrompt({
            //     key: [
            //       'params',
            //       'scheduler',
            //       `${loadedPrompt.species}_${loadedPrompt.gender}`,
            //     ],
            //     data: npromptRef.current.value,
            //   })
            // );

            if (saveAsData.product === "") {
              const newGroup = {
                name: saveAsData.newProduct,
                parent: saveAsData.category,
                production: false,
              };
              const prompt = JSON.parse(JSON.stringify(loadedPrompt));
              prompt.prompts[`${loadedPrompt.species}_${loadedPrompt.gender}`] =
                promptRef.current.value;
              prompt.nprompts[
                `${loadedPrompt.species}_${loadedPrompt.gender}`
              ] = npromptRef.current.value;

              delete prompt._id;
              const newPrompt = {
                ...prompt,
                name: saveAsData.name,
                parent: saveAsData.newProduct,
                category: saveAsData.category,
                author: user,
                modified_by: user,
                created_at: createdAt,
              };

              addSubcategory(newGroup).then((resp) => {
                if (resp.success) {
                  addPrompt(newPrompt)
                    .unwrap()
                    .then((resp) => {
                      if (resp.success) {
                        setAlert({
                          show: true,
                          severity: "success",
                          msg: "Prompt saved",
                        });
                        setSaveDialogData(false);
                        dispatch(
                          setLoadedPrompt({ key: "_id", data: resp._id })
                        );
                        dispatch(
                          setLoadedPrompt({ key: "name", data: newPrompt.name })
                        );
                        dispatch(
                          setLoadedPrompt({
                            key: "parent",
                            data: newPrompt.parent,
                          })
                        );
                        dispatch(
                          setLoadedPrompt({
                            key: "category",
                            data: newPrompt.category,
                          })
                        );
                        dispatch(
                          setLoadedPrompt({ key: "author", data: user })
                        );
                        dispatch(
                          setLoadedPrompt({ key: "modified_by", data: user })
                        );
                        dispatch(
                          setLoadedPrompt({
                            key: "created_at",
                            data: createdAt,
                          })
                        );
                      } else {
                        setAlert({
                          show: true,
                          severity: "error",
                          msg: resp.msg,
                        });
                        setSaveDialogData(false);
                      }
                    });
                } else {
                  setAlert({
                    show: true,
                    severity: "error",
                    msg: resp.msg,
                  });
                  setSaveDialogData(false);
                }
              });
            } else {
              // parent: selectedGroup,
              const prompt = JSON.parse(JSON.stringify(loadedPrompt));
              prompt.prompts[`${loadedPrompt.species}_${loadedPrompt.gender}`] =
                promptRef.current.value;
              prompt.nprompts[
                `${loadedPrompt.species}_${loadedPrompt.gender}`
              ] = npromptRef.current.value;
              delete prompt._id;

              const newPrompt = {
                ...prompt,
                name: saveAsData.name,
                parent: saveAsData.product,
                category: saveAsData.category,
                author: user,
                modified_by: user,
                created_at: createdAt,
              };
              addPrompt(newPrompt)
                .unwrap()
                .then((resp) => {
                  if (resp.success) {
                    setAlert({
                      show: true,
                      severity: "success",
                      msg: "Prompt saved",
                    });
                    setSaveDialogData(false);
                    dispatch(setLoadedPrompt({ key: "_id", data: resp._id }));
                    dispatch(
                      setLoadedPrompt({ key: "name", data: newPrompt.name })
                    );
                    dispatch(
                      setLoadedPrompt({ key: "parent", data: newPrompt.parent })
                    );
                    dispatch(
                      setLoadedPrompt({
                        key: "category",
                        data: newPrompt.category,
                      })
                    );
                    dispatch(setLoadedPrompt({ key: "author", data: user }));
                    dispatch(
                      setLoadedPrompt({ key: "modified_by", data: user })
                    );
                    dispatch(
                      setLoadedPrompt({ key: "created_at", data: createdAt })
                    );
                  } else {
                    setAlert({
                      show: true,
                      severity: "error",
                      msg: resp.msg,
                    });
                    setSaveDialogData(false);
                  }
                });
            }
          }
        });
    }
  }, [saveDialogData]);

  // UNLOCK SAVE PROMPT BUTTON
  useEffect(() => {
    if (
      saveAsData.name !== "" &&
      saveAsData.category !== "" &&
      (saveAsData.product !== "" || saveAsData.newProduct !== "")
    ) {
      setsavePromptBlocked(false);
    } else {
      setsavePromptBlocked(true);
    }
  }, [saveAsData]);

  const updateMissingParams = (prompt) => {
    const params = [
      "cfg",
      "lorastrength",
      "refiner",
      "refinersteps",
      "sampler",
      "steps",
      "scheduler",
    ];
    const defaults = [7, 1, false, 25, "Euler", 30, "beta"];
    const keys = [
      "cat_female",
      "cat_male",
      "dog_female",
      "dog_male",
      "human_female",
      "human_male",
    ];

    params.forEach((param, index) => {
      const value = defaults[index];
      keys.forEach((key) => {
        if (prompt.params[param][key] === "") {
          dispatch(
            setLoadedPrompt({
              key: ["params", param, key],
              data: value,
            })
          );
        }
      });
    });
  };

  const handleLoadPromptFromDb = async () => {
    const prompt = await getPrompt(loadedPrompt._id).unwrap();
    setPromptToLoad(prompt.data);
  };
  // LOADIN PROMPT AND SETTING DEPENDANT STATES
  useEffect(() => {
    if (promptToLoad) {
      dispatch(
        setOrderLoaded({
          orderLoaded: false,
          data: { uuid: "", name: "r?.name " },
        })
      );

      dispatch(setSelectedCheckpoint(promptToLoad.checkpoint));
      dispatch(setSaveAsData({ key: "name", data: "" }));
      dispatch(setSaveAsData({ key: "category", data: promptToLoad.category }));
      dispatch(setSaveAsData({ key: "product", data: promptToLoad.parent }));

      dispatch(setLoadedPrompt({ key: "", data: promptToLoad }));
      updateMissingParams(promptToLoad);

      dispatch(setPromptListDialogOpen(false));
      promptRef.current.value =
        promptToLoad.prompts[`${promptToLoad.species}_${promptToLoad.gender}`];
      npromptRef.current.value =
        promptToLoad.nprompts[`${promptToLoad.species}_${promptToLoad.gender}`];

      const promptKey = `${promptToLoad.species}_${promptToLoad.gender}`;

      dispatch(setExtraLoras(promptToLoad.extraLoras));
      console.log(promptToLoad);
      getPromptByName(promptToLoad.name).then((resp) => {
        if (resp.success) {
          setpromptId(resp.msg);
          getHistory(resp.msg).then((resp) => {
            if (resp.success) {
              const history = [...resp.msg];
              const p = {
                ...promptToLoad,
                history: history,
                checkpointVersion: promptToLoad.checkpointVersion,
                loaded: true,
              };
              setpromptHistoryList(history);
              savePromptLocally(p);
            }
          });
        } else {
          console.error(resp.msg);
        }
      });
    }
  }, [promptToLoad]);

  // CLEAN PROMPT
  const handleRevertPrompt = async () => {
    dispatch(
      setOrderLoaded({
        orderLoaded: false,
        data: { uuid: "", name: "r?.name " },
      })
    );
    dispatch(setLoadedPrompt({ key: "", data: prompt_dataclass }));
    dispatch(setDefaultInitialState(initialState));
    dispatch(setSelectedCheckpoint("1_sd_xl_base_1.0"));
    dispatch(setSaveAsData({ key: "name", data: "" }));
    dispatch(setSaveAsData({ key: "category", data: "" }));
    dispatch(setSaveAsData({ key: "product", data: "" }));

    promptRef.current.value = "";
    npromptRef.current.value = "";

    const fluxSamplersList = await get_flux_samplers().unwrap();
    dispatch(setFluxSamplers(fluxSamplersList.samplers));

    const sch = await get_schedulers().unwrap();
    dispatch(setSchedulers(sch.schedulers));
  };

  // UPDATE PROMPT
  const handleUpdatePrompt = async (saveAll) => {
    console.log("update prompt");
    // console.log(loadedPrompt);
    // return false;
    const key = `${loadedPrompt.species}_${loadedPrompt.gender}`;
    dispatch(
      setLoadedPrompt({
        key: ["checkpoint"],
        data: checkpoint,
      })
    );
    dispatch(
      setLoadedPrompt({
        key: ["prompts", `${loadedPrompt.species}_${loadedPrompt.gender}`],
        data: promptRef.current.value,
      })
    );
    dispatch(
      setLoadedPrompt({
        key: ["nprompts", `${loadedPrompt.species}_${loadedPrompt.gender}`],
        data: npromptRef.current.value,
      })
    );
    dispatch(
      setLoadedPrompt({
        key: "modified_by",
        data: username,
      })
    );
    loadedPrompt.author === "" &&
      dispatch(
        setLoadedPrompt({
          key: "author",
          data: username,
        })
      );
    var prompt = JSON.parse(JSON.stringify(loadedPrompt));
    // prompt._id = loadedPrompt._id;
    if (!saveAll) {
      const oldValues = await getPrompt(loadedPrompt._id).unwrap();
      console.log(oldValues);
      console.log(Object.isFrozen(oldValues.data));

      prompt.prompts = JSON.parse(JSON.stringify(oldValues.data.prompts));
      prompt.nprompts = JSON.parse(JSON.stringify(oldValues.data.nprompts));
      prompt.prompts[`${loadedPrompt.species}_${loadedPrompt.gender}`] =
        promptRef.current.value;
      prompt.nprompts[`${loadedPrompt.species}_${loadedPrompt.gender}`] =
        npromptRef.current.value;

      const temp = prompt.params;
      prompt.params = JSON.parse(JSON.stringify(oldValues.data.params));
      prompt.params.cfg[`${loadedPrompt.species}_${loadedPrompt.gender}`] =
        temp.cfg[`${loadedPrompt.species}_${loadedPrompt.gender}`];
      prompt.params.lorastrength[
        `${loadedPrompt.species}_${loadedPrompt.gender}`
      ] = temp.lorastrength[`${loadedPrompt.species}_${loadedPrompt.gender}`];
      prompt.params.refiner[`${loadedPrompt.species}_${loadedPrompt.gender}`] =
        temp.refiner[`${loadedPrompt.species}_${loadedPrompt.gender}`];
      prompt.params.refinersteps[
        `${loadedPrompt.species}_${loadedPrompt.gender}`
      ] = temp.refinersteps[`${loadedPrompt.species}_${loadedPrompt.gender}`];
      prompt.params.sampler[`${loadedPrompt.species}_${loadedPrompt.gender}`] =
        temp.sampler[`${loadedPrompt.species}_${loadedPrompt.gender}`];
      prompt.params.steps[`${loadedPrompt.species}_${loadedPrompt.gender}`] =
        temp.steps[`${loadedPrompt.species}_${loadedPrompt.gender}`];
    } else {
      prompt.prompts[`${loadedPrompt.species}_${loadedPrompt.gender}`] =
        promptRef.current.value;
      prompt.nprompts[`${loadedPrompt.species}_${loadedPrompt.gender}`] =
        npromptRef.current.value;
    }

    prompt.modified_by = username;
    console.log(`Author: ${prompt.author}`);
    if (prompt.author === "" || prompt.author === undefined) {
      prompt.author = username;
    }
    console.log(prompt);
    updatePrompt(prompt)
      .unwrap()
      .then((resp) => {
        if (resp.success) {
          setAlert({
            show: true,
            severity: "success",
            msg: "Prompt saved",
          });
          updateHistory(promptId, promptHistoryList).then((resp) => {
            if (resp.success) {
              console.log("succes");
            } else {
              console.log(resp);
            }
          });
        } else {
          setAlert({
            show: true,
            severity: "error",
            msg: resp.msg,
          });
        }
      });
  };

  const generateHires = () => {
    setshowBackdrop(true);
    sethiresGeneration(true);
    console.log(hiresList);
    hiresList.forEach((job, index) => {
      hiresList[index].lora = loraThumbsList[job.job];
    });

    submitHiresJobs(hiresList).then((resp) => {
      if (resp.status === "success") {
        setbatchStatus(1);
        dispatch(setIsGenerating(true));
        setResultImage([]);
        setLoraThumbsList([]);
        setResultSeeds([]);
        setStarted(true);
        settotalJobs(resp.task_id.length);
        setHiresJobs(resp.loras);
        sethiresList([]);
        setshowBackdrop(false);
        setAlert({
          show: true,
          severity: "success",
          msg: "Hires jobs added",
        });
      }
    });
    setshowBackdrop(false);
  };

  return (
    <>
      <Toast alert={alert} setAlert={setAlert}></Toast>
      <Backdrop
        sx={{ color: "#fff", zIndex: "99999" }}
        open={showBackdrop}
        onClick={() => setshowBackdrop(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Sidebar handleRevertPrompt={handleRevertPrompt} />
      {/* <MenuBar /> */}
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        padding="25px 20px"
        sx={{
          mt: 0,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            alignSelf: "flex-start",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "25px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "space-between",
                justifyContent: "start",
                gap: "5px",
                // backgroundColor: theme.palette.yellows.main,
                height: "44px",
                marginLeft: "-20px",
                padding: "5px 20px",
                borderTopRightRadius: "10px",
                borderBottomRightRadius: "0px",
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleRevertPrompt}
                  sx={{ marginRight: "15px" }}
                >
                  New
                </Button>
                {loadedPrompt.name !== "" && (
                  <>
                    <Tooltip title="Save only currently selected gender species">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleUpdatePrompt(false)}
                        disabled={loadedPrompt.author !== username && !isAdmin}
                      >
                        Save current
                      </Button>
                    </Tooltip>
                    <Tooltip title="Save all gender species">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleUpdatePrompt(true)}
                        disabled={
                          (loadedPrompt.author !== username && !isAdmin) ||
                          orderLoaded
                        }
                      >
                        Save all
                      </Button>
                    </Tooltip>
                  </>
                )}
                {/* SAVE PROMPT DIALOG */}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setSaveDialogOpen(true)}
                  sx={{ marginRight: "10px" }}
                >
                  Save as
                </Button>
                <SaveAs
                  open={saveDialogOpen}
                  setOpen={setSaveDialogOpen}
                  setSaveDialogData={setSaveDialogData}
                  savePromptBlocked={savePromptBlocked}
                />
                {orderLoaded && (
                  <Tooltip title="Load saved prompt from DB">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleLoadPromptFromDb()}
                      sx={{
                        backgroundColor: "#3BB9DC",
                        color: "#000",
                        "&:hover": {
                          backgroundColor: "#208EAB",
                        },
                      }}
                    >
                      RELOAD
                    </Button>
                  </Tooltip>
                )}
                <Tooltip title="Open prompts list">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => dispatch(setPromptListDialogOpen(true))}
                    sx={{
                      backgroundColor: "#3BB9DB",
                      color: "#000",
                      "&:hover": {
                        backgroundColor: "#208EAC",
                      },
                    }}
                  >
                    OPEN LIST
                  </Button>
                </Tooltip>
                <PromptsList
                  setPromptToLoad={setPromptToLoad}
                  appliedFilters={appliedFilters}
                  applyFilters={setAppliedFilters}
                />
                {/* <FullScreenDialog
                  open={promptListDialogOpen}
                  setOpen={setPromptListDialogOpen}
                  setPromptToLoad={setPromptToLoad}
                /> */}
              </Box>
              <Tooltip title="Open orders history for currently laoded prompt">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    dispatch(toggleQueueDrawer(true));
                  }}
                  sx={{
                    marginLeft: "20px",
                    backgroundColor: "#BBFF00",
                    color: "#000",
                    "&:hover": {
                      backgroundColor: "#A5E000",
                    },
                  }}
                >
                  ORDERS
                </Button>
              </Tooltip>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                marginTop: "23px",
              }}
            >
              <CheckpointSettings />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "3px",
                  // marginTop: '23px',
                }}
              >
                <Dropdown
                  name="Species"
                  id="speciesDropdown"
                  value={loadedPrompt.species}
                  setValue={(value) => {
                    handleChangeSpeciesOrGender();
                    dispatch(setLoadedPrompt({ key: "species", data: value }));
                  }}
                  items={speciesValues}
                />
                <Dropdown
                  name="Gender"
                  id="genderDropdown"
                  value={loadedPrompt.gender}
                  setValue={(value) => {
                    handleChangeSpeciesOrGender();
                    dispatch(setLoadedPrompt({ key: "gender", data: value }));
                  }}
                  items={genderValues}
                />
                <Dropdown
                  name="Production"
                  id="productionDropdown"
                  value={loadedPrompt.isProduction}
                  setValue={(value) =>
                    dispatch(
                      setLoadedPrompt({ key: "isProduction", data: value })
                    )
                  }
                  items={productionValues}
                />
              </Box>
            </Box>
          </Box>
          {/* {isAdmin && (
            <ProductGenerate
              selectedLoras={selectedLoras}
              checkpoint={loadedPrompt.checkpoint}
              gender={loadedPrompt.gender}
              species={loadedPrompt.species}
              setIsGenerating={() => dispatch(setIsGenerating)}
              setResultImage={setResultImage}
              setResultSeeds={setResultSeeds}
              setStarted={setStarted}
              settotalJobs={settotalJobs}
              setproductGeneration={setproductGeneration}
              setBatchStatus={setbatchStatus}
              setproductsCount={setproductsCount}
              setLoraThumbsList={setLoraThumbsList}
            />
          )} */}
        </Box>
        <Box
          sx={{
            mt: 1,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <TextField
            margin="normal"
            id="width"
            label="width"
            name="width"
            value={loadedPrompt.width}
            onChange={(event) =>
              dispatch(
                setLoadedPrompt({ key: "width", data: event.target.value })
              )
            }
            className="ring-0"
            sx={{ width: "100px" }}
            size="small"
            onKeyDown={(e) => e.stopPropagation()}
          />
          <TextField
            margin="normal"
            id="height"
            label="height"
            name="height"
            value={loadedPrompt.height}
            onChange={(event) =>
              dispatch(
                setLoadedPrompt({ key: "height", data: event.target.value })
              )
            }
            className="ring-0"
            sx={{ width: "100px" }}
            size="small"
            onKeyDown={(e) => e.stopPropagation()}
          />
          {/* <TextField
            margin="normal"
            id="batch"
            label="batch"
            name="batch"
            value={batchSize}
            onChange={(event) =>
              setbatchSize(
                hires
                  ? 1
                  : parseInt(event.target.value) > 5
                  ? 5
                  : event.target.value
              )
            }
            className="ring-0"
            sx={{ width: '100px' }}
            size="small"
          /> */}

          {/* TODO */}
          <TextField
            margin="normal"
            id="batch"
            label="batch"
            name="batch"
            value={batch}
            onChange={(event) =>
              dispatch(
                setBatch(
                  hires.enabled
                    ? 1
                    : loras.filter((l) => l.selected).length === 1
                    ? parseInt(event.target.value)
                    : parseInt(event.target.value) > 5
                    ? 5
                    : event.target.value
                )
              )
            }
            className="ring-0"
            sx={{ width: "100px" }}
            size="small"
            onKeyDown={(e) => e.stopPropagation()}
          />

          <TextField
            margin="normal"
            id="steps"
            label={faceSwapEnabled ? "steps canny" : "steps"}
            name="steps"
            autoComplete="steps"
            value={
              loadedPrompt.params.steps[
                `${loadedPrompt.species}_${loadedPrompt.gender}`
              ]
            }
            onChange={(event) =>
              dispatch(
                setLoadedPrompt({
                  key: [
                    "params",
                    "steps",
                    `${loadedPrompt.species}_${loadedPrompt.gender}`,
                  ],
                  data: event.target.value,
                })
              )
            }
            className="ring-0"
            sx={{ width: "100px" }}
            size="small"
            onKeyDown={(e) => e.stopPropagation()}
          />
          <TextField
            margin="normal"
            id="seed"
            label="seed"
            name="seed"
            autoComplete="seed"
            value={loadedPrompt.seed}
            onChange={(event) =>
              dispatch(
                setLoadedPrompt({ key: "seed", data: event.target.value })
              )
            }
            className="ring-0"
            sx={{ width: "130px" }}
            size="small"
            onKeyDown={(e) => e.stopPropagation()}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: "5px",
              ml: "-5px",
            }}
          >
            <IconButton
              size="small"
              onClick={() =>
                dispatch(setLoadedPrompt({ key: "seed", data: -1 }))
              }
            >
              <CasinoIcon fontSize="inherit" color="primary" />
            </IconButton>
            {/* <IconButton size="small" onClick={handleReuseSeed}>
              <RecyclingIcon fontSize="inherit" color="primary" />
            </IconButton> */}
          </Box>
          {isFlux ? (
            <>
              <FormControl
                className="ring-0"
                size="small"
                sx={{ mt: "7px", width: "150px" }}
              >
                <InputLabel id="demo-simple-select-label">sampler</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  inputProps={{ id: () => makeid(5) }}
                  label="sampler"
                  value={
                    flux_samplers.includes(
                      loadedPrompt.params.sampler[
                        `${loadedPrompt.species}_${loadedPrompt.gender}`
                      ]
                    )
                      ? loadedPrompt.params.sampler[
                          `${loadedPrompt.species}_${loadedPrompt.gender}`
                        ]
                      : "euler"
                  }
                  onChange={(event) =>
                    dispatch(
                      setLoadedPrompt({
                        key: [
                          "params",
                          "sampler",
                          `${loadedPrompt.species}_${loadedPrompt.gender}`,
                        ],
                        data: event.target.value,
                      })
                    )
                  }
                >
                  {flux_samplers.map((sampler, index) => {
                    return (
                      <MenuItem key={index} value={sampler}>
                        {sampler}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl
                className="ring-0"
                size="small"
                sx={{ mt: "7px", width: "150px" }}
              >
                <InputLabel id="demo-simple-select-label">scheduler</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  inputProps={{ id: () => makeid(5) }}
                  label="scheduler"
                  value={
                    loadedPrompt.params.scheduler[
                      `${loadedPrompt.species}_${loadedPrompt.gender}`
                    ]
                  }
                  onChange={(event) =>
                    dispatch(
                      setLoadedPrompt({
                        key: [
                          "params",
                          "scheduler",
                          `${loadedPrompt.species}_${loadedPrompt.gender}`,
                        ],
                        data: event.target.value,
                      })
                    )
                  }
                >
                  {schedulers.map((scheduler, index) => {
                    return (
                      <MenuItem key={index} value={scheduler.replace(" ", "")}>
                        {scheduler.replace(" ", "")}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </>
          ) : (
            <FormControl
              className="ring-0"
              size="small"
              sx={{ mt: "7px", width: "150px" }}
            >
              <InputLabel id="demo-simple-select-label">sampler</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                inputProps={{ id: () => makeid(5) }}
                label="sampler"
                value={
                  loadedPrompt.params.sampler[
                    `${loadedPrompt.species}_${loadedPrompt.gender}`
                  ]
                }
                onChange={(event) =>
                  dispatch(
                    setLoadedPrompt({
                      key: [
                        "params",
                        "sampler",
                        `${loadedPrompt.species}_${loadedPrompt.gender}`,
                      ],
                      data: event.target.value,
                    })
                  )
                }
              >
                {samplers.map((sampler, index) => {
                  return (
                    <MenuItem key={index} value={sampler}>
                      {sampler}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}

          {/* <StepedSlider
            label={isFlux ? "guidance" : "CFG scale"}
            value={
              loadedPrompt.params.cfg[
                `${loadedPrompt.species}_${loadedPrompt.gender}`
              ]
            }
            setValue={(value) =>
              dispatch(
                setLoadedPrompt({
                  key: [
                    "params",
                    "cfg",
                    `${loadedPrompt.species}_${loadedPrompt.gender}`,
                  ],
                  data: value,
                })
              )
            }
            step={0.1}
            min={0}
            max={10}
            width={300}
            marks={true}
          /> */}
          <TextField
            margin="normal"
            id="guidance"
            label={isFlux ? "guidance" : "CFG scale"}
            name="guidance"
            autoComplete="guidance"
            value={
              loadedPrompt.params.cfg[
                `${loadedPrompt.species}_${loadedPrompt.gender}`
              ]
            }
            onChange={(event) =>
              dispatch(
                setLoadedPrompt({
                  key: [
                    "params",
                    "cfg",
                    `${loadedPrompt.species}_${loadedPrompt.gender}`,
                  ],
                  data: event.target.value,
                })
              )
            }
            className="ring-0"
            sx={{ width: "170px" }}
            size="small"
            type="number"
            onKeyDown={(e) => e.stopPropagation()}
          />
          {!isFlux && (
            <FormGroup>
              <FormControlLabel
                sx={{ marginTop: "5px", marginLeft: "10px", color: "#fff" }}
                control={
                  <Checkbox
                    id="refinerCheckbox"
                    onChange={(event) =>
                      dispatch(
                        setLoadedPrompt({
                          key: [
                            "params",
                            "refiner",
                            `${loadedPrompt.species}_${loadedPrompt.gender}`,
                          ],
                          data: event.target.checked,
                        })
                      )
                    }
                    checked={
                      loadedPrompt.params.refiner[
                        `${loadedPrompt.species}_${loadedPrompt.gender}`
                      ]
                    }
                  />
                }
                label="Refiner"
              />
            </FormGroup>
          )}
          {!isFlux &&
            loadedPrompt.params.refiner[
              `${loadedPrompt.species}_${loadedPrompt.gender}`
            ] && (
              <TextField
                margin="normal"
                id="refinerSteps"
                label="steps"
                name="refinerSteps"
                value={
                  loadedPrompt.params.refinersteps[
                    `${loadedPrompt.species}_${loadedPrompt.gender}`
                  ]
                }
                onChange={(event) => handleSetRefinerSteps(event.target.value)}
                className="ring-0"
                sx={{ width: "100px" }}
                size="small"
                type="number"
                onKeyDown={(e) => e.stopPropagation()}
              />
            )}
          {!isFlux && (
            <FormGroup>
              <FormControlLabel
                sx={{ marginTop: "5px", marginLeft: "10px", color: "#fff" }}
                control={
                  <Checkbox
                    id="hiresCheckbox"
                    checked={hires.enabled}
                    onChange={(event) => handleSetHires(event.target.checked)}
                  />
                }
                label="Hires"
              />
            </FormGroup>
          )}
          {!isFlux && hires.enabled && (
            <TextField
              margin="normal"
              id="upscaleBy"
              label="upscale by"
              name="upscaleBy"
              value={hires.scale}
              onChange={(event) =>
                dispatch(setHires({ key: "scale", data: event.target.value }))
              }
              className="ring-0"
              sx={{ width: "100px" }}
              size="small"
              onKeyDown={(e) => e.stopPropagation()}
            />
          )}

          {isFlux && (
            <>
              <FormGroup sx={{ paddingLeft: "10px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="faceswapselect"
                      checked={faceSwapEnabled}
                      onChange={(event) =>
                        setfaceSwapEnabled(event.target.checked)
                      }
                    />
                  }
                  label="Faceswap"
                  // sx={{ color: g1 }}
                />
              </FormGroup>
            </>
          )}
          {faceSwapEnabled && (
            <>
              <input
                type="file"
                onChange={(e) => setfaceSwapImage(e.target.files)}
                accept="image/*"
              />
            </>
          )}
        </Box>
        {faceSwapEnabled && (
          <Box
            sx={{
              mt: 1,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <Box width="100px"></Box>
            <Box width="100px"></Box>
            <Box width="100px"></Box>
            <TextField
              margin="normal"
              id="steps2"
              label="steps inpaint"
              name="steps2"
              autoComplete="steps"
              value={
                loadedPrompt.params.hasOwnProperty("steps2")
                  ? loadedPrompt.params.steps2[
                      `${loadedPrompt.species}_${loadedPrompt.gender}`
                    ]
                  : 20
              }
              onChange={(event) =>
                dispatch(
                  setLoadedPrompt({
                    key: [
                      "params",
                      "steps2",
                      `${loadedPrompt.species}_${loadedPrompt.gender}`,
                    ],
                    data: event.target.value,
                  })
                )
              }
              className="ring-0"
              sx={{ width: "100px" }}
              size="small"
              onKeyDown={(e) => e.stopPropagation()}
            />
            <Box width="155px"></Box>
            <FormControl
              className="ring-0"
              size="small"
              sx={{ mt: "7px", width: "150px" }}
            >
              <InputLabel id="demo-simple-select-label">
                sampler inpaint
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                inputProps={{ id: () => makeid(5) }}
                label="sampler"
                value={
                  loadedPrompt.params.hasOwnProperty("sampler2")
                    ? loadedPrompt.params.sampler2[
                        `${loadedPrompt.species}_${loadedPrompt.gender}`
                      ]
                    : "euler"
                }
                onChange={(event) =>
                  dispatch(
                    setLoadedPrompt({
                      key: [
                        "params",
                        "sampler2",
                        `${loadedPrompt.species}_${loadedPrompt.gender}`,
                      ],
                      data: event.target.value,
                    })
                  )
                }
              >
                {flux_samplers.map((sampler, index) => {
                  return (
                    <MenuItem key={index} value={sampler}>
                      {sampler}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl
              className="ring-0"
              size="small"
              sx={{ mt: "7px", width: "150px" }}
            >
              <InputLabel id="demo-simple-select-label">
                scheduler inpaint
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                inputProps={{ id: () => makeid(5) }}
                label="scheduler"
                value={
                  loadedPrompt.params.hasOwnProperty("scheduler2")
                    ? loadedPrompt.params.scheduler2[
                        `${loadedPrompt.species}_${loadedPrompt.gender}`
                      ]
                    : "beta"
                }
                onChange={(event) =>
                  dispatch(
                    setLoadedPrompt({
                      key: [
                        "params",
                        "scheduler2",
                        `${loadedPrompt.species}_${loadedPrompt.gender}`,
                      ],
                      data: event.target.value,
                    })
                  )
                }
              >
                {schedulers.map((scheduler, index) => {
                  return (
                    <MenuItem key={index} value={scheduler.replace(" ", "")}>
                      {scheduler.replace(" ", "")}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <TextField
              margin="normal"
              id="guidance2"
              label="guidance inpaint"
              name="guidance2"
              autoComplete="guidance"
              value={
                loadedPrompt.params.hasOwnProperty("guidance")
                  ? loadedPrompt.params.guidance[
                      `${loadedPrompt.species}_${loadedPrompt.gender}`
                    ]
                  : 40
              }
              onChange={(event) =>
                dispatch(
                  setLoadedPrompt({
                    key: [
                      "params",
                      "guidance",
                      `${loadedPrompt.species}_${loadedPrompt.gender}`,
                    ],
                    data: event.target.value,
                  })
                )
              }
              className="ring-0"
              sx={{ width: "170px" }}
              size="small"
              onKeyDown={(e) => e.stopPropagation()}
            />
          </Box>
        )}
        <PromptTokens species={loadedPrompt.species} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            gap: "3px",
          }}
        >
          {/* <TextField
            margin="normal"
            required
            fullWidth
            id="prompt"
            label="Prompt"
            name="prompt"
            autoComplete="prompt"
            value={prompt}
            onChange={(event) => setPrompt(event.target.value)}
            multiline={true}
            autoFocus
            className="ring-0"
          /> */}

          <TextField
            margin="normal"
            required
            fullWidth
            id="prompt"
            label="Prompt"
            name="prompt"
            autoComplete="prompt"
            inputRef={promptRef}
            multiline={true}
            // autoFocus
            className="ring-0"
            onKeyDown={(e) => e.stopPropagation()}
            onBlur={(e) => {
              e.stopPropagation();
              dispatch(
                setLoadedPrompt({
                  key: [
                    "prompts",
                    `${loadedPrompt.species}_${loadedPrompt.gender}`,
                  ],
                  data: promptRef.current.value,
                })
              );
            }}
          />

          <PromptHistoryDialog
            setPrompt={promptRef}
            promptHistoryList={promptHistoryList}
          ></PromptHistoryDialog>
        </Box>

        <TextField
          margin="normal"
          required
          fullWidth
          id="nprompt"
          inputProps={{ id: "npromptId" }}
          InputLabelProps={{ for: "npromptId" }}
          label="Negative prompt"
          name="nprompt"
          multiline={true}
          inputRef={npromptRef}
          autoComplete="prompt"
          className="ring-0"
          disabled={isFlux}
          onKeyDown={(e) => e.stopPropagation()}
          onBlur={(e) => {
            e.stopPropagation();
            dispatch(
              setLoadedPrompt({
                key: [
                  "nprompts",
                  `${loadedPrompt.species}_${loadedPrompt.gender}`,
                ],
                data: npromptRef.current.value,
              })
            );
          }}
        />
        <Box display="flex" flexDirection="row" gap="5px" alignItems="center">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, width: "200px" }}
            disabled={
              !isFlux
                ? loras.filter((l) => l.selected).length !== 0
                  ? checkpoint === ""
                    ? true
                    : false
                  : true
                : false
            }
          >
            {" "}
            GENERATE
          </Button>
        </Box>
        <SmallDialog
          title={
            <div style={{ color: "#fecd27", fontSize: "24px" }}>Warning</div>
          }
          content={
            <>
              <p>
                <span
                  style={{
                    fontWeight: "700",
                    fontSize: "14px",
                    textDecoration: "underline",
                  }}
                >
                  width
                </span>{" "}
                or{" "}
                <span
                  style={{
                    fontWeight: "700",
                    fontSize: "14px",
                    textDecoration: "underline",
                  }}
                >
                  height
                </span>{" "}
                exceeds default values used for generation.
              </p>{" "}
              <p>Are you sure you want to proceed ? </p>
            </>
          }
          open={shwoResolutionWarning}
          setOpen={setshwoResolutionWarning}
          setData={setResolutionWarningData}
          confirmButton="Generate"
          cancelButton="Cancel"
          blocked={false}
        ></SmallDialog>
      </Box>

      {hiresList.length !== 0 && (
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2, width: "200px" }}
          disabled={hiresList.length === 0 ? true : false}
          onClick={generateHires}
        >
          {" "}
          HIRES SELECTED
        </Button>
      )}
    </>
  );
};

export default Generate;

// resultImage.map((img, index) => {
//   return (
//     <img
//       src={`data:image/jpeg;base64,${img}`}
//       alt=""
//       width="300"
//       key={index}
//     />
//   );
// })
