export const generateEndpoints = (builder) => {
  return {
    checkpoints: builder.mutation({
      query: () => ({
        url: "/models",
        method: "get",
      }),
    }),
    loras: builder.mutation({
      query: () => ({
        url: "/get_loras",
        method: "get",
      }),
    }),
    flux_loras: builder.mutation({
      query: () => ({
        url: "/flux_loras",
        method: "get",
      }),
    }),
    samplers: builder.mutation({
      query: () => ({
        url: "/samplers",
        method: "get",
      }),
    }),
    flux_samplers: builder.mutation({
      query: () => ({
        url: "/flux_samplers",
        method: "get",
      }),
    }),
    schedulers: builder.mutation({
      query: () => ({
        url: "/schedulers",
        method: "get",
      }),
    }),
    jobInfo: builder.mutation({
      query: (job) => ({
        url: `/api/v2/job/${job}`,
        method: "get",
      }),
    }),

    generate: builder.mutation({
      query: ({ username, prompt, payloads, settings }) => ({
        url: `/generate?type=1`,
        method: "post",
        body: {
          user: username,
          user_id: "65b2381451b2b5db88f42a3f",
          payloads: payloads,
          prompt: prompt,
          settings: settings,
        },
      }),
    }),
    comfy: builder.mutation({
      query: ({ username, prompt, payloads, settings }) => ({
        url:
          settings.checkpoint === "shuttle"
            ? `/comfy?type=1&model=shuttle`
            : `/comfy?type=1`,
        method: "post",
        body: {
          user: username,
          user_id: "65b2381451b2b5db88f42a3f",
          payloads: payloads,
          prompt: prompt,
          settings: settings,
        },
      }),
    }),
    upscale: builder.mutation({
      query: ({ order_id, username, promptName, urls }) => ({
        url: `/upscale/${order_id}?type=1`,
        method: "post",
        body: {
          creator: username,
          user_id: "65b2381451b2b5db88f42a3f",
          urls: urls,
          prompt_name: promptName,
        },
      }),
    }),
    faceswap: builder.mutation({
      query: ({ formData, model }) => ({
        url: `/faceswap?type=0&model=${model}`,
        method: "post",
        body: formData,
      }),
    }),
  };
};
