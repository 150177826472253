import { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiRef,
} from "@mui/x-data-grid";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { useCms } from "../../../../utils/CMS";
import { useGenerate } from "../../Context";

import ContextMenu from "./ContextMenu";
import { Progress, TextWrapCellRenderer } from "components/SmallComponents";
import Toast from "components/Toast";
import { Button } from "@mui/material";
import { Delete, Fullscreen } from "@mui/icons-material";
import {
  useDeleteSingleOrderMutation,
  useJobInfoMutation,
  useUpdateOrderMutation,
} from "api/apiSlice";
import {
  setBatch,
  setHires,
  setLoadedPrompt,
  setOrderLoaded,
  setSelectedCheckpoint,
  setSelectedLoras,
} from "state/generate";

function formatDate(inputString) {
  // Parse the input string into a Date object
  const dateObj = new Date(inputString * 1000);
  // Format the date components
  const day = String(dateObj.getDate()).padStart(2, "0");
  const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = dateObj.getFullYear();
  const hours = String(dateObj.getHours()).padStart(2, "0");
  const minutes = String(dateObj.getMinutes()).padStart(2, "0");
  const seconds = String(dateObj.getSeconds()).padStart(2, "0");

  // Construct the formatted date string
  const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

  return formattedDate;
}

const columns = [
  {
    field: "state",
    headerName: "state",
    width: 50,
    type: "boolean",
  },
  {
    field: "name",
    headerName: "name",
    width: 150,
    type: "string",
    editable: true,
  },
  {
    field: "uuid",
    headerName: "_id",
    width: 300,
    type: "string",
  },
  {
    field: "creator",
    headerName: "user",
    width: 140,
    type: "string",
  },
  {
    field: "genspec",
    headerName: "species | gender",
    width: 140,
    type: "string",
  },
  {
    field: "status",
    headerName: "status",
    width: 100,
    type: "boolean",
  },
  {
    field: "progress",
    headerName: "progress",
    width: 100,
    type: "string",
  },

  {
    field: "created_at",
    headerName: "created at",
    width: 170,
    editable: false,
  },
];

export default function DataGridQueue({ setClose, handleRevertPrompt }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [updateOrder] = useUpdateOrderMutation();
  const [getJobInfo] = useJobInfoMutation();
  const [deleteOrder] = useDeleteSingleOrderMutation();

  const orderLoaded = useSelector((state) => state.generate.orderLoaded);
  const orderData = useSelector((state) => state.generate.orderData);
  const apiRef = useGridApiRef();
  const loadedPrompt = useSelector((state) => state.generate.loadedPrompt);
  const { getOrders } = useCms();
  const { loadedPromptName } = useGenerate();
  const [rows, setrows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setloading] = useState(true);

  const [alert, setAlert] = useState({
    show: false,
    severity: "success",
    msg: "",
  });

  const valueRef = useRef(orderData.uuid); // Ref to hold the latest value
  valueRef.current = orderData.uuid;

  const handleSelectionChange = (selection) => {
    // Map selected IDs to the corresponding row data
    const selectedData = rows.filter((row) => selection.includes(row.id));
    console.log(selectedData);
    setSelectedRows(selectedData);
  };

  async function gr() {
    console.log(`from  gr(): ${valueRef.current}`);
    const orders = await getOrders(loadedPrompt._id);
    console.log(orders.data[orders.data.length - 1]);
    const o = orders.data.map((r, index) => {
      var temp = { ...r };
      temp.id = index;
      temp.created_at = formatDate(r.started_at);
      temp.progress = calculateProgress(temp.jobs);
      temp.genspec = `${r?.species}_${r?.gender}`;
      temp.state = r.final;
      if (orderLoaded) {
        temp.orderLoaded = r.uuid === valueRef.current ? true : false;
      }
      return temp;
    });
    setrows(o);
  }

  const calculateProgress = (jobs) => {
    const total = Object.keys(jobs).length;
    const completed = Object.values(jobs).filter((value) => value === 1).length;

    return `${completed} of ${total}`;
  };

  useEffect(() => {
    if (loadedPrompt._id !== "") {
      if (rows.length === 0) {
        gr();
      }

      const interval = setInterval(() => {
        gr();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    console.log(orderData.uuid);
    setloading(false);
  }, [rows]);

  const handleOnCellClick = (params) => {
    console.log(params);

    // if (params.field === 'image') {
    //   window.open(params.value, '_blank', 'noreferrer');
    // }
  };

  const [contextMenu, setContextMenu] = useState(null);
  const [rowClicked, setrowClicked] = useState(null);
  const handleRowContextMenu = (event) => {
    event.preventDefault();
    const rowId = Number(event.currentTarget.getAttribute("data-id"));
    setrowClicked(rowId);

    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleViewImages = (event) => {
    // console.log(event);
    // const rowId = Number(event.currentTarget.getAttribute('data-id'));
    // const r = apiRef.current.getRow(rowId);
    var nw = window.open(`/order/${event.row.uuid}`, "_blank");
    nw.blur();
    window.focus();
    setContextMenu(null);
  };

  // const markAsCompleted = () => {
  //   const record = rows.find((row) => row.id === rowClicked);
  //   console.log(apiRef.current.getRow(rowClicked));
  //   const r = apiRef.current.getRow(rowClicked);
  //   if (!r.reviewed) {
  //     updateReport({ q: { uuid: record.uuid }, s: { reviewed: true } }).then(
  //       (resp) => {
  //         if (resp.status === 'success') {
  //           apiRef.current.updateRows([{ id: rowClicked, reviewed: true }]);
  //         }
  //       }
  //     );
  //   } else {
  //     updateReport({ q: { uuid: record.uuid }, s: { reviewed: false } }).then(
  //       (resp) => {
  //         if (resp.status === 'success') {
  //           apiRef.current.updateRows([{ id: rowClicked, reviewed: false }]);
  //         }
  //       }
  //     );
  //   }
  // };

  const handleRename = (value) => {
    const record = rows.find((row) => row.id === rowClicked);
    console.log(apiRef.current.getRow(rowClicked));
    const r = apiRef.current.getRow(rowClicked);
    setContextMenu(null);
    apiRef.current.updateRows([{ id: rowClicked, name: value }]);
    updateOrder({ uuid: r.uuid, key: "name", value: value })
      .unwrap()
      .then((resp) => {});
  };

  const loadPrompt = async () => {
    const r = apiRef.current.getRow(rowClicked);
    console.clear();
    console.log(`load order: ${r.uuid}`);
    const job = Object.keys(r.jobs)[0];
    const info = await getJobInfo(job).unwrap();

    console.log(info.raw_prompt.extraLoras);
    if (Object.hasOwn(info, "raw_prompt")) {
      dispatch(setBatch(info.raw_prompt.batch));
      dispatch(setSelectedCheckpoint(info.raw_prompt.checkpoint));
      dispatch(
        setHires({ key: "enabled", data: info.raw_prompt.hires.enabled })
      );
      dispatch(setHires({ key: "scale", data: info.raw_prompt.hires.scale }));
      dispatch(setSelectedLoras(info.raw_prompt.selectedLoras));
      dispatch(
        setLoadedPrompt({ key: "", data: info.raw_prompt.loadedPrompt })
      );
      dispatch(
        setLoadedPrompt({ key: "extraLoras", data: info.raw_prompt.extraLoras })
      );
      dispatch(setLoadedPrompt({ key: "seed", data: info.payload.seed }));
      const row = apiRef.current
        .getAllRowIds()
        .map((id) => apiRef.current.getRow(id))
        .filter((row) => row.uuid === orderData.uuid);
      console.log(row);
      row.length !== 0 &&
        apiRef.current.updateRows([{ id: row[0].id, orderLoaded: false }]);
      dispatch(
        setOrderLoaded({
          orderLoaded: true,
          data: { uuid: r.uuid, name: r?.name },
        })
      );
      r.orderLoaded = true;

      // const updatedRows = allRows.map((row) => {
      //   if (row.uuid === orderData.uuid) {
      //     return { id: row.id, orderLoaded: false };
      //   } else {
      //     return { id: row.id, orderLoaded: true };
      //   }
      //   // New value for the 'name' field
      // });

      setAlert({
        show: true,
        severity: "success",
        msg: "Order loaded.",
      });
    } else {
      setAlert({
        show: true,
        severity: "error",
        msg: "Incompatible order.",
      });
    }

    setContextMenu(null);
    // check if raw_prompt key exists if not ask user if he wants to load everything beside prompt value
    // dispatch all params from payload
  };

  const handleMarkAsFinal = () => {
    const record = rows.find((row) => row.id === rowClicked);
    const r = apiRef.current.getRow(rowClicked);
    apiRef.current.updateRows([{ id: rowClicked, final: !r.final }]);
    updateOrder({ uuid: r.uuid, key: "final", value: !r.final }).unwrap();

    setContextMenu(null);
  };

  const handleDelete = async () => {
    setContextMenu(null);
    const r = apiRef.current.getRow(rowClicked);
    apiRef.current.updateRows([{ id: r.uuid, _action: "delete" }]);
    const res = await deleteOrder(r.uuid);
    if (orderLoaded && orderData.uuid === r.uuid) {
      await handleRevertPrompt();
    }

    setAlert({
      show: true,
      severity: "success",
      msg: "Order deleted.",
    });
  };

  async function handleMultiDelete() {
    if (selectedRows.length === 0) {
      setAlert({
        show: true,
        severity: "error",
        msg: `Please select at least 1 order`,
      });
      return false;
    }
    const _rs = await Promise.all(
      selectedRows.map(async (row) => {
        apiRef.current.updateRows([{ id: row.uuid, _action: "delete" }]);
        const res = await deleteOrder(row.uuid);
        return row.uuid;
      })
    );
    handleSelectionChange([]);
    if (orderLoaded && _rs.includes(orderData.uuid)) {
      await handleRevertPrompt();
    }
    setAlert({
      show: true,
      severity: "success",
      msg: `${_rs.length} orders deleted.`,
    });
  }

  return (
    <>
      <Toast alert={alert} setAlert={setAlert} />
      <Box sx={{ height: "100vh", width: "100%" }}>
        <DataGrid
          apiRef={apiRef}
          rows={rows}
          columns={columns}
          slots={{ toolbar: CustomToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              handleMultiDelete: handleMultiDelete,
            },
            row: {
              onContextMenu: handleRowContextMenu,
              style: { cursor: "pointer" },
            },
            loadingOverlay: Progress,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 50,
              },
            },
            filter: {
              filterModel: {
                items: [],
                quickFilterExcludeHiddenColumns: true,
              },
            },
            columns: {
              columnVisibilityModel: {
                index: false,
                job: false,
                order: false,
                generated_at: false,
                _id: false,
              },
            },
          }}
          pageSizeOptions={[50]}
          onCellClick={handleOnCellClick}
          onRowClick={(event) => handleViewImages(event)}
          loading={loading}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newSelectionModel) => {
            handleSelectionChange(newSelectionModel);
          }}
          // Optional: Control the selected rows programmatically
          selectionModel={selectedRows.map((row) => row.id)}
          getRowClassName={(params) =>
            params.row.orderLoaded ? "highlighted-row" : ""
          }
          sx={{
            ".MuiDataGrid-row.highlighted-row": {
              backgroundColor: "lightblue", // Apply background color here
              color: "#000",
            },
          }}
        />
      </Box>
      <ContextMenu
        contextMenu={contextMenu}
        handleClose={handleClose}
        rename={handleRename}
        loadPrompt={loadPrompt}
        handleMarkAsFinal={handleMarkAsFinal}
        handleDelete={handleDelete}
      />
    </>
  );
}

function CustomToolbar({ handleMultiDelete }) {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton
        slotProps={{ button: { sx: { color: "#fff" } } }}
      />
      <GridToolbarFilterButton
        slotProps={{ button: { sx: { color: "#fff" } } }}
      />
      <GridToolbarDensitySelector
        slotProps={{
          tooltip: { title: "Change density" },
          button: { sx: { color: "#fff" } },
        }}
      />
      <Button
        variant="text"
        startIcon={<Fullscreen />}
        sx={{ color: "#fff" }}
        onClick={() => window.open(`/orders`, "_blank")}
      >
        OPEN
      </Button>
      <Button
        variant="text"
        startIcon={<Delete />}
        sx={{ color: "#fff" }}
        onClick={handleMultiDelete}
      >
        DELETE
      </Button>
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
}
